import { fromThrowable } from 'neverthrow'
import { type App, createApp } from 'vue'

import AppSFC from './App.vue'
import './styles/main.scss'

fromThrowable(() => {
  const search = new URLSearchParams(location.search)
  const inviteCode = search.get('invite')
  console.info('📔 Search:', location.search)
  console.table(Object.fromEntries(search.entries()))
  if (inviteCode !== null && inviteCode.length > 0) {
    sessionStorage.setItem('invite-code', inviteCode)
  }
})()

const app = createApp(AppSFC)

app.config.errorHandler = (err, vm, info) => {
  if (typeof err === 'object' && err instanceof Error && err.message?.includes('Failed to fetch dynamically imported module')) {
    console.warn('动态导入模块失败，正在刷新页面...')
    setTimeout(() => {
      window.location.reload()
    }, 100)
  }
  else {
    console.warn('🚨', err, vm, info)
  }
}

Object.values(
  import.meta.glob<{ install: (app: App) => void }>('./lib/modules/*.ts', {
    eager: true,
  }),
).forEach(({
  install,
}) =>
  install(app),
)

app.mount('#app')
