<script setup lang="ts">
import { VueQueryDevtools } from '@tanstack/vue-query-devtools'
import { fromAsyncThrowable } from 'neverthrow'
import { Toaster } from './components/ui/sonner'

useAuthGuard()
useDark({ initialValue: 'light' }).value = false
const route = useRoute()
const audioPlayer = useTemplateRef('audioPlayer')
onMounted(() => {
  if (audioEnabled.value && route.name === '/') {
    playMusic()
  }
})

watch(audioEnabled, (enabled) => {
  if (enabled) {
    playMusic()
  }
  else {
    audioPlayer.value?.pause()
  }
})

watch(() => route.name, (name) => {
  if (name !== '/') {
    audioPlayer.value?.pause()
  }
  else if (audioEnabled.value && audioPlayer.value?.paused) {
    playMusic()
  }
})

function playIfEnabled() {
  if (audioEnabled.value && route.name === '/') {
    if (audioPlayer.value?.paused) {
      playMusic()
    }
  }
}

function playMusic() {
  fromAsyncThrowable(async () => window.navigator?.userActivation?.hasBeenActive && audioPlayer.value?.play())()
}
</script>

<template>
  <main @touchstart="playIfEnabled" @mousedown="playIfEnabled">
    <RouterView />
    <VueQueryDevtools />
    <WarehouseAnimationOverlay />
    <Toaster position="top-center" close-button class="pointer-events-auto" @click="(e: Event) => e.stopImmediatePropagation()" />
    <UpdateToaster />
    <audio ref="audioPlayer" :autoplay="audioEnabled" loop src="/audio/background.mp3" />
  </main>
</template>
